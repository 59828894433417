import React, { useState, useEffect } from 'react';
import Layout, { FullWidthContainer } from '../../components/Layout';
import { graphql } from 'gatsby';
import Header from '../../components/Header';
import GeneratorBox from '../../components/GeneratorBox';
import Button from '../../components/Button';
import { generateIcon } from '../../utilities/icons';
import AboutSection from '../../components/AboutSection';
import CategorySection from '../../components/CategorySection';
import DisclaimerSection from '../../components/DisclaimerSection';
import Footer from '../../components/Footer';
import Meta from '../../components/Meta';
import { randNumberRange } from '../../utilities/random';
import CategoryCardSection from '../../components/CategoryCardSection';
import { Helmet } from 'react-helmet';

const Category = ({ pageContext, data }) => {
  const {
    title,
    description,
  } = data.mongodbGeneratorninjaCategories;
  const { category } = pageContext;

    let pages = [];
    data.allMongodbGeneratorninjaFixedattrs.nodes.forEach((node) => {
        pages.push({icon: node.icon, title: node.title, slug: node.slug});
    });
    data.allMongodbGeneratorninjaRandomattrs.nodes.forEach((node) => {
        pages.push({icon: node.icon, title: node.title, slug: node.slug});
    });
    data.allMongodbGeneratorninjaNocards.nodes.forEach((node) => {
        pages.push({icon: node.icon, title: node.title, slug: node.slug});
    });
    const pagesLength = pages.length;
    const [randomNumber, setRandomNumber] = useState(randNumberRange(0, pagesLength));


    const getRandomNumber = () => {
      let randNumber = randNumberRange(0, pagesLength);
      if (pagesLength > 1) {
        while (randNumber === randomNumber) {
          randNumber = randNumberRange(0, pagesLength);
        }
      }
      setRandomNumber(randNumber);
    }

    return (
        <Layout>
          <>
            <Meta title={title} description={description} path={pageContext.slug}  />
            <FullWidthContainer>
              <>
                <Header type="h1" text={title} />
                <Button type="Link" light={false} text={pages[randomNumber].title} href={pages[randomNumber].slug} icon={pages[randomNumber].icon} />
                <Button type="Function" light={true} text={`Generate Another Generator`} handleClick={getRandomNumber} icon={generateIcon} />
              </>
            </FullWidthContainer>
            <CategoryCardSection sectionTitle={title} pages={pages}  />
            <CategorySection />
            <DisclaimerSection />
            <Footer />
            </>
        </Layout>
    )
}

export default Category;

export const query = graphql`
query CategoryTemplate($slug: String!, $category: String!) {
    mongodbGeneratorninjaCategories(slug: {eq: $slug}) {
      title
      description
    }
    allMongodbGeneratorninjaRandomattrs(
      filter: {active: {eq: true}, category: {eq: $category}}
    ) {
      nodes {
        title
        icon
        slug
      }
    }
    allMongodbGeneratorninjaNocards(
      filter: {active: {eq: true}, category: {eq: $category}}
    ) {
      nodes {
        title
        icon
        slug
      }
    }
    allMongodbGeneratorninjaFixedattrs(
      filter: {active: {eq: true}, category: {eq: $category}}
    ) {
      nodes {
        title
        icon
        slug
      }
    }
  }
`;
