import React from 'react';
import Header from '../Header';
import Card from '../Card';
import * as styles from './CategoryCardSection.module.scss';

interface Props {
    sectionTitle: string,
    pages: any[],
}

const CategoryCardSection = ({ pages, sectionTitle }: Props) => {
    return (
        <div className={styles.popular}>
          <Header type="h2" text={sectionTitle} light={true} />
          <div className={styles.popularCards}>
            {pages.map((pop) =>
              <Card key={pop.title} title={pop.title} category={pop.category} icon={pop.icon} url={pop.slug} />
            )}
          </div>
        </div>
    )
}

export default CategoryCardSection;
